/* eslint-disable func-names */
import {
  AnyObject,
  bool,
  boolean,
  date,
  InferType,
  mixed,
  NumberSchema,
  object,
  ObjectSchema,
  string,
  TestContext,
} from 'yup'
import { formatDate, convertToLocalDate } from '@src/services/Formatter'
import { isLastDayOfMonth, isWeekend } from 'date-fns'
import { min } from 'lodash-es'
import { Constants, EFrequencyType, EPaymentPlan, EWorksheetStatus, PaymentMethod } from './Constants'
import yupExtInt from './common/SchemaTypes'

const MIN_TERM = 12

export const worksheetDetailsSchema = object({
  amountRequested: yupExtInt.double
    .required('common.errors.required')
    .positive()
    .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
    .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount'),

  deliveryOn: string().default(new Date().toDateString()).nonNullable().required('common.errors.required'),

  paymentFrequency: mixed<EFrequencyType>().default(null).required(),
  paymentPlanId: mixed<EPaymentPlan>().required().default(EPaymentPlan.regularDailyInterests),

  firstPaymentOn: string().test('deliveryOn-test', function (value?: string) {
    const deliveryOn = (this.parent as { deliveryOn: string }).deliveryOn
    const twoMonthsLater = new Date(deliveryOn)
    twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

    return !deliveryOn || !value || (new Date(value) > new Date(deliveryOn) && new Date(value) <= twoMonthsLater)
  }),
  term: yupExtInt.integer.min(MIN_TERM).nonNullable().required(),

  creditApplicationId: string().default(null).nullable(),

  id: string().default(null).nullable(),

  versionTag: string().default(''),
})

export type WorsheetDetailsDto = InferType<typeof worksheetDetailsSchema>

export const buildEditPersonalLoanWorksheetDtoSchema = (
  hardHitReportReceivedOn: Date,
  finalDecisonMaxAmountFinanced: number,
  listHolidays: Date[],
) => {
  return worksheetDetailsSchema.shape({
    status: string().required().default(EWorksheetStatus.Draft),

    amountRequested: (worksheetDetailsSchema.fields.amountRequested as NumberSchema)
      .required()
      .max(finalDecisonMaxAmountFinanced, 'common.errors.lowerOrEqual_finalDecisonMaxAmountFinanced')
      .test('total-merchant-payment-test', 'common.errors.notEqualMerchantPayment', function (value) {
        const totalMerchantPayment = (this.parent as { merchantPayments: MerchantPayment[] }).merchantPayments.reduce(
          (acc, curr) => Number(acc) + Number(curr.amount),
          0,
        )
        return totalMerchantPayment === value
      }),

    deliveryOn: string()
      .default(formatDate(new Date()).toString())
      .required('common.errors.required')
      .typeError('common.errors.required')
      .test('expiresOn-test', 'common.errors.deliveryOnLowerThanExpiresOn', function (value) {
        const deliveryDate = new Date(value)
        const expiredDate = new Date(hardHitReportReceivedOn)
        expiredDate.setDate(expiredDate.getDate() + 90)
        return deliveryDate <= expiredDate
      })
      .test('deliveryOn-test', 'common.errors.deliveryOnGreaterOrEqualThanToday', function (value) {
        const deliveryDate = new Date(value)
        deliveryDate.setUTCHours(0, 0, 0, 0)

        const now = new Date()
        now.setUTCHours(0, 0, 0, 0)

        return now <= deliveryDate
      })
      .test('deliveryOn-test', 'common.errors.deliveryOnIsHoliday', function (value) {
        const deliveryDate = convertToLocalDate(value)
        const isHoliday = listHolidays?.toString().includes(formatDate(deliveryDate))
        const isNotEndOfMonthAndWeekday = isWeekend(deliveryDate) && !isLastDayOfMonth(deliveryDate)

        return !isHoliday && !isNotEndOfMonthAndWeekday
      }),

    includeInsurance: bool().default(false),

    addFastPayments: bool().default(false),

    fundConfirmationNote: string().default(null).nullable(),

    merchantPayments: mixed<MerchantPayment[]>().default([]),
  })
}

const testInsuranceSchemaField = (textContext: TestContext<AnyObject>) => {
  const values = textContext.parent as ProductsInsurance

  const anyFieldProvided = !!values.amount || !!values.term || !!values.police

  const field = textContext.path.split('.')[1] as keyof ProductsInsurance
  const value = values[field]

  if (anyFieldProvided && !value)
    return textContext.resolve(textContext.createError({ path: textContext.path, message: 'common.errors.required' }))

  return true
}

export const baseInsuranceSchema = object({
  provider: string().nullable().default(null),
  police: string()
    .nullable()
    .default(null)
    .test(function () {
      return testInsuranceSchemaField(this)
    }),
  term: yupExtInt.integer.min(MIN_TERM).test(function () {
    return testInsuranceSchemaField(this)
  }),
  amount: yupExtInt.double.test(function () {
    return testInsuranceSchemaField(this)
  }),
})

export type ProductsInsurance = InferType<typeof baseInsuranceSchema>

const resolveInsuranceMaxTerm = (schema: ObjectSchema<ProductsInsurance>, vehicleFinancingTerm: number) => {
  if (vehicleFinancingTerm)
    schema.fields.term = (schema.fields.term as NumberSchema<number | null | undefined, AnyObject, null, 'd'>).max(
      vehicleFinancingTerm,
    )

  return schema
}

const resolveMaxTerm = (
  schema: NumberSchema<number | undefined, AnyObject, undefined, ''>,
  values: ProductsInsurance[],
) => {
  const terms: number[] = []

  values.forEach((e) => {
    if (e) if (e.term) terms.push(e.term)
  })

  const max = min(terms)

  if (max) {
    return schema.max(max)
  }

  return schema
}

export const ProductWorksheetSchema = worksheetDetailsSchema.shape({
  amountRequested: (worksheetDetailsSchema.fields.amountRequested as NumberSchema).min(
    Constants.ProductMinimumLoanAmount,
  ),
  deliveryOn: string()
    .isValidDate()
    .default(() => formatDate(new Date()))
    .nullable()
    .required('common.errors.required')
    .typeError('common.errors.required'),
  firstPaymentOn: date()
    .default(() => {
      const firstPaymentDate = new Date()
      firstPaymentDate.setDate(firstPaymentDate.getDate())
      return firstPaymentDate
    })
    .required('common.errors.required')
    .typeError('common.errors.required'),
  term: (worksheetDetailsSchema.fields.term as NumberSchema)
    .required()
    .when(
      ['extendedWarranty', 'replacementOrGapInsurance', 'creditInsurance'],
      (values: ProductsInsurance[], schema) => {
        return resolveMaxTerm(schema, values)
      },
    ),
  vehicleFinancingTerm: yupExtInt.integer.nonNullable().required('common.errors.required').min(MIN_TERM).max(240),
  extendedWarranty: baseInsuranceSchema
    .when('vehicleFinancingTerm', ([vehicleFinancingTerm], schema) => {
      return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
    })
    .nullable(),
  replacementOrGapInsurance: baseInsuranceSchema
    .when('vehicleFinancingTerm', ([vehicleFinancingTerm], schema) => {
      return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
    })
    .nullable(),
  creditInsurance: baseInsuranceSchema
    .when('vehicleFinancingTerm', ([vehicleFinancingTerm], schema) => {
      return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
    })
    .nullable(),
})

export type ProductsWorksheet = InferType<typeof ProductWorksheetSchema>

export const EditPersonalLoanWorksheetDtoSchema = buildEditPersonalLoanWorksheetDtoSchema(
  new Date(Date.now() + 90 * 24 * 60 * 60 * 1000), // Adds 90 days in milliseconds to the current date
  Constants.MaximumLoanAmount,
  [],
)

export type EditPersonalLoanWorksheetDto = InferType<typeof EditPersonalLoanWorksheetDtoSchema>

export type EditPersonalLoanWorksheetDtoDefaultValue = InferType<typeof EditPersonalLoanWorksheetDtoSchema>

export const merchantPaymentSchema = object({
  merchantId: string().required(),
  amount: yupExtInt.double.positive().required(),
  paymentMethod: string().default(PaymentMethod.bankTransfer).required().oneOf(Object.values(PaymentMethod)),
})

export type MerchantPayment = InferType<typeof merchantPaymentSchema>

export const computePossibleTermsDtoSchema = object({
  merchantId: string().required('common.errors.required'),
  paymentPlanId: string().required('common.errors.required'),
  interestRate: yupExtInt.double.positive().required('common.errors.required'),
  hasCoapplicant: boolean().required('common.errors.required'),
  stateIso: string().required('common.errors.required'),
  paymentFrequency: string().oneOf(Object.values(EFrequencyType)).required('common.errors.required'),
  deliveryOn: string().default(new Date().toDateString()).nonNullable().required('DO'),
  firstPaymentOn: date().test('deliveryOn-test', function (value?: Date) {
    const deliveryOn = (this.parent as { deliveryOn: string }).deliveryOn
    const twoMonthsLater = new Date(deliveryOn)
    twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

    return !deliveryOn || !value || (value > new Date(deliveryOn) && value <= twoMonthsLater)
  }),
  maxPmtAmount: yupExtInt.double.positive().required('common.errors.required'),
  amountRequested: yupExtInt.double
    .required('AR')
    .positive()
    .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
    .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount'),
  includeInsurance: boolean().required('common.errors.required'),
})

export type ComputePossibleTermsDto = InferType<typeof computePossibleTermsDtoSchema>
