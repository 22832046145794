import { CardContent, CircularProgress, Typography } from '@mui/material'
import { CardBorderTop } from '@src/components/common/Card'
import { FormatCurrency, FormatPercentage } from '@src/services/Formatter'
import { ProductsComputeResultDto } from '@src/types/ProductsComputeResultDto'
import { useTranslation } from 'react-i18next'

type Props = { computedFunding: ProductsComputeResultDto | null; isFetching?: boolean }

const ProductsLoanFundingSummary = ({ computedFunding, isFetching = false }: Props) => {
  const { t } = useTranslation()

  const showAnnualFees = (computedFunding?.annualFees ?? 0) > 0

  return (
    <CardBorderTop
      sx={{
        padding: 2,
        height: 'fit-content',
        maxWidth: { md: '25rem' },
        minWidth: '25rem',
      }}
    >
      <CardContent>
        {isFetching && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {computedFunding ? (
          <>
            {/* Main message - loan payment */}
            <Typography variant="h6" gutterBottom>
              {t('calculator.monthlyPayment')}
            </Typography>
            <Typography variant="h4" color="primary" gutterBottom>
              {FormatCurrency(computedFunding.paymentAmountForFrequency)}
            </Typography>

            {/* Total interest costs */}
            <Typography variant="body1" gutterBottom>
              {t('calculator.totalInterestCosts', {
                rate: FormatPercentage(computedFunding.interestRate),
              })}
            </Typography>

            {/* Additional details */}
            <Typography variant="body2" color="textSecondary">
              {t('calculator.effectiveRate', { effectiveRate: computedFunding.effectiveRate })}
            </Typography>
            {showAnnualFees && (
              <Typography variant="body2" color="textSecondary">
                {t('calculator.annualFees', { annualFees: computedFunding.annualFees })}
              </Typography>
            )}
          </>
        ) : (
          !isFetching && (
            <Typography variant="body1" color="textSecondary">
              {t('calculator.detailsAvailable')}
            </Typography>
          )
        )}
      </CardContent>
    </CardBorderTop>
  )
}

export default ProductsLoanFundingSummary
