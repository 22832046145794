import { Box, Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material'
import SelectComponent from '@src/components/SelectComponent'
import { t } from 'i18next'
import { ProductsProvider } from '@src/types/ProductsProvider'
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { ProductsInsurance, ProductsWorksheet } from '@src/types/WorksheetSchema'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { EProductsInsurance, EProvince } from '@src/types/Constants'
import ProductsInsuranceDetails from './ProductsInsuranceDetails'

interface Props {
  insurancesProvider: ProductsProvider[]
  warrantyProvider: ProductsProvider[]
  register: UseFormRegister<ProductsWorksheet>
  setValue: UseFormSetValue<ProductsWorksheet>
  errors: FieldErrors<ProductsWorksheet>
  watch: UseFormWatch<ProductsWorksheet>
  getValues: UseFormGetValues<ProductsWorksheet>
  trigger: UseFormTrigger<ProductsWorksheet>
  stateIso: EProvince
}

const ProductsInsurances = ({
  insurancesProvider,
  warrantyProvider,
  register,
  setValue,
  errors,
  watch,
  getValues,
  trigger,
  stateIso,
}: Props) => {
  let defaultInsuranceProvider: string | null = null

  getValues(['replacementOrGapInsurance', 'creditInsurance']).forEach((e) => {
    if (e?.provider && !defaultInsuranceProvider) {
      defaultInsuranceProvider = e?.provider
    }
  })

  const [insuranceProvider, setInsuranceProvider] = useState<string | null>(defaultInsuranceProvider)
  const [extendedWarrantyProvider, setExtendedWarrantyProvider] = useState<string | null>(
    getValues('extendedWarranty.provider'),
  )
  const [insuranceProviderError, setInsuranceProvideError] = useState<string | undefined>(undefined)
  const [extendedWarrantyError, setExtendedWarrantyError] = useState<string | undefined>(undefined)

  const anyFieldProvided = (values: ProductsInsurance | null) => {
    if (!values) return false
    return !!values.amount || !!values.term || !!values.police
  }

  const [creditInsurance, replacementOrGapInsurance, extendedWarranty] = watch([
    'creditInsurance',
    'replacementOrGapInsurance',
    'extendedWarranty',
  ])

  useEffect(() => {
    const validate = (key: EProductsInsurance, provider: string | null, fieldProvided: boolean) => {
      if (fieldProvided) {
        setValue(`${key}.provider`, provider)
      } else {
        setValue(`${key}.provider`, null)
        // eslint-disable-next-line no-void
        void trigger(key)
      }
    }

    const anyReplacementOrGapField = anyFieldProvided(replacementOrGapInsurance as ProductsInsurance)
    const anyCreditField = anyFieldProvided(creditInsurance as ProductsInsurance)
    const anyExtendedField = anyFieldProvided(extendedWarranty as ProductsInsurance)

    validate(EProductsInsurance.CreditInsurance, insuranceProvider, anyCreditField)
    validate(EProductsInsurance.ReplacementOrGapInsurance, insuranceProvider, anyReplacementOrGapField)
    validate(EProductsInsurance.ExtendedWarranty, extendedWarrantyProvider, anyExtendedField)

    const error =
      (anyReplacementOrGapField || anyCreditField) && !insuranceProvider ? 'common.errors.required' : undefined

    setInsuranceProvideError(error)
    setExtendedWarrantyError(anyExtendedField && !extendedWarrantyProvider ? 'common.errors.required' : undefined)

    const getPremium = (value: number | null | undefined) => {
      const amount = Number(value)
      return Number.isNaN(amount) ? 0 : amount
    }

    const amount =
      getPremium((replacementOrGapInsurance as ProductsInsurance)?.amount) +
      getPremium((creditInsurance as ProductsInsurance)?.amount) +
      getPremium((extendedWarranty as ProductsInsurance)?.amount)

    setValue('amountRequested', amount)
  }, [
    insuranceProvider,
    extendedWarrantyProvider,
    setValue,
    trigger,
    getValues,
    creditInsurance?.amount,
    replacementOrGapInsurance?.amount,
    extendedWarranty?.amount,
    replacementOrGapInsurance?.police,
    creditInsurance?.police,
    extendedWarranty?.police,
    replacementOrGapInsurance?.provider,
    creditInsurance?.provider,
    extendedWarranty?.provider,
    replacementOrGapInsurance?.term,
    creditInsurance?.term,
    extendedWarranty?.term,
    replacementOrGapInsurance,
    creditInsurance,
    extendedWarranty,
  ])

  const insuranceProviders = useMemo(() => {
    const ret = insurancesProvider.map((provider) => ({
      label: provider.name,
      value: provider.id,
    }))
    ret.push({ label: '', value: '' })
    return ret
  }, [insurancesProvider])

  const warrantyProviders = useMemo(() => {
    const ret = warrantyProvider.map((provider) => ({
      label: provider.name,
      value: provider.id,
    }))
    ret.push({ label: '', value: '' })
    return ret
  }, [warrantyProvider])

  return (
    <Stack direction={{ lg: 'row', md: 'column' }} spacing={1} justifyContent="space-between">
      <Card>
        <CardHeader title={t('productsWorksheet.insurances')} />
        <CardContent>
          <SelectComponent
            error={insuranceProviderError}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setInsuranceProvider(e.target.value)
            }}
            valueList={insuranceProviders}
            defaultValue={defaultInsuranceProvider}
            label={t('common.provider') as string}
          />
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2, mt: 2 }}>
                {stateIso === EProvince.quebec
                  ? t('productsWorksheet.replacementInsurance')
                  : t('productsWorksheet.gapInsurance')}
              </Typography>
              <ProductsInsuranceDetails
                register={register}
                insurance={EProductsInsurance.ReplacementOrGapInsurance}
                errors={errors}
              />
            </Box>

            <Box>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2, mt: 2 }}>
                {t('productsWorksheet.creditInsurance')}
              </Typography>
              <ProductsInsuranceDetails
                register={register}
                insurance={EProductsInsurance.CreditInsurance}
                errors={errors}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={t('productsWorksheet.extendedWarranty')} />
        <CardContent>
          <SelectComponent
            defaultValue={extendedWarrantyProvider}
            valueList={warrantyProviders}
            error={extendedWarrantyError}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setExtendedWarrantyProvider(e.target.value)
            }}
            label={t('common.provider') as string}
          />
          <Box>
            <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2, mt: 2 }}>
              {t('productsWorksheet.extendedWarranty')}
            </Typography>
            <ProductsInsuranceDetails
              register={register}
              insurance={EProductsInsurance.ExtendedWarranty}
              errors={errors}
            />
          </Box>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default ProductsInsurances
