import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import InputTextField from '@src/components/InputTextField'
import SelectMerchantsDialog from '@src/components/SelectMerchantsDialog'
import {
  createNewPersonalLoanWorksheetDto,
  getPersonalFundingDto,
  isVariableInterest,
  provinceSupportsInsurance,
  updatePersonalLoanWorksheet,
} from '@src/data/worksheet-selectors'
import { FormatCurrency, formatDate, normalizeNumber } from '@src/services/Formatter'
import { Constants, EProvince } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { UserDtoFromJwt } from '@src/types/User'
import {
  EditPersonalLoanWorksheetDto,
  EditPersonalLoanWorksheetDtoSchema,
  buildEditPersonalLoanWorksheetDtoSchema,
} from '@src/types/WorksheetSchema'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Info as InfoIcon, Add as AddIcon } from '@mui/icons-material'
import {
  useGetPersonalFunding,
  usePostPersonalLoanWorksheet,
  usePutPersonalLoanWorksheet,
} from '@src/data/api/worksheet-api/personal-loan-worksheet-api'
import { useGetPossibleTerms } from '@src/data/api/worksheet-api/worksheet-api'
import ConflictDialog from '@src/containers/App/components/ConflictDialog'
import { Conflict } from '@src/types/Conflict'
import PageSpinner from '@src/components/PageSpinner'
import {
  useComputeFirstPaymentDateOptions,
  useFeatureFlags,
  usePaymentMerchantList,
} from '../ViewCreditApplicationPage/worksheet-hooks'
import PersonalLoanFundingSummary from './components/PersonalLoanFundingSummary'
import WorksheetDetails from './components/worksheetDetails'

type Props = {
  listHolidays: Date[]
  merchant: Merchant
  creditApplication: CreditApplication
  user: UserDtoFromJwt
  merchantsListFromParentId: Merchant[]
  merchantPayments: { label: string; value: string; id: string }[]
}
const PersonalLoanWorksheet = ({
  listHolidays,
  merchant,
  creditApplication,
  user,
  merchantsListFromParentId,
  merchantPayments,
}: Props) => {
  const defaultValues = creditApplication.worksheet
    ? creditApplication.worksheet
    : EditPersonalLoanWorksheetDtoSchema.getDefault()

  const {
    control,
    register,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditPersonalLoanWorksheetDto>({
    mode: 'onBlur',
    defaultValues: defaultValues as unknown as EditPersonalLoanWorksheetDto,
    resolver: yupResolver(
      buildEditPersonalLoanWorksheetDtoSchema(
        creditApplication.applicant.hardHitReportReceivedOn!,
        creditApplication.finalCreditDecision.maxAmountFinanced!,
        listHolidays,
      ),
    ),
  })
  const [postWorksheet, isPosting, isPostError, postError, resetPost] = usePostPersonalLoanWorksheet()
  const [putWorksheet, isPuting, isPutError, updateError, resetPut] = usePutPersonalLoanWorksheet()
  const [openUserDialog, setOpenUserDialog] = React.useState<boolean>(false)
  const [openInsuranceInfoBox, setOpenInsuranceInfoBox] = useState(false)
  const deliveryOn = watch('deliveryOn')
  const amountRequested = normalizeNumber(watch('amountRequested')?.toString()) ?? 0
  const paymentFrequency = watch('paymentFrequency') ?? 'monthly'
  const term = watch('term')
  const firstPaymentOn = watch('firstPaymentOn') as string
  const paymentPlanId = watch('paymentPlanId')
  const includeInsurance = watch('includeInsurance')
  const firstPaymentDateOptions = useComputeFirstPaymentDateOptions(deliveryOn, listHolidays)
  const hasVariableInterest = isVariableInterest(paymentPlanId)
  const { flags, isFetchingFeatureSwitch } = useFeatureFlags()
  const doesProvinceSupportInsurance = provinceSupportsInsurance(
    creditApplication.applicant.currentAddress.stateIso,
    flags.UseIncludeInsuranceAllProvinces,
  )
  const { merchantList, setMerchantList } = usePaymentMerchantList(
    creditApplication,
    merchantsListFromParentId,
    merchant,
    user,
  )
  const navigate = useNavigate()
  const { t } = useTranslation()
  const conflict = postError
    ? (postError?.response?.data as unknown as Conflict)
    : (updateError?.response?.data as unknown as Conflict)

  const dto = useMemo(() => {
    return getPersonalFundingDto(
      creditApplication?.id,
      amountRequested,
      paymentFrequency,
      term,
      deliveryOn,
      new Date(firstPaymentOn),
      includeInsurance,
      creditApplication.applicant.currentAddress.stateIso,
      paymentPlanId,
      creditApplication.finalCreditDecision.interestRate,
    )
  }, [
    creditApplication,
    amountRequested,
    deliveryOn,
    firstPaymentOn,
    includeInsurance,
    paymentFrequency,
    paymentPlanId,
    term,
  ])
  const computeMonthTermFrequency = useMemo(
    () => ({
      merchantId: creditApplication.merchantId,
      paymentPlanId,
      interestRate: creditApplication.finalCreditDecision.interestRate,
      hasCoapplicant: creditApplication.coApplicant !== null,
      stateIso: creditApplication.applicant.currentAddress.stateIso as EProvince,
      paymentFrequency,
      deliveryOn,
      firstPaymentOn,
      maxPmtAmount: creditApplication.finalCreditDecision.maxPmtAmount,
      amountRequested,
      includeInsurance,
      include84Months: amountRequested >= Constants.LargeLoanAmount,
    }),
    [
      creditApplication.merchantId,
      paymentPlanId,
      creditApplication.finalCreditDecision.interestRate,
      creditApplication.coApplicant,
      creditApplication.applicant.currentAddress.stateIso,
      paymentFrequency,
      deliveryOn,
      firstPaymentOn,
      creditApplication.finalCreditDecision.maxPmtAmount,
      amountRequested,
      includeInsurance,
    ],
  )
  const [possibleTerms, isLoadingTerms] = useGetPossibleTerms(computeMonthTermFrequency)

  const loanTermChoicesList =
    possibleTerms?.monthlyTerms.map((item) => ({
      label: `${item.term} ${t('common.month')}`,
      value: item.term,
    })) || []

  const [computedPersonalLoanFunding, isComputing] = useGetPersonalFunding(creditApplication.id, dto)

  const onClosedMerchantDialog = useCallback(() => {
    setOpenUserDialog(false)
  }, [])
  const doesTermSupportInsurance = useMemo(() => {
    if (!possibleTerms?.monthlyTerms) return false
    const matchingTerm = possibleTerms.monthlyTerms.find((item) => item.term === term)

    return matchingTerm ? matchingTerm.effectiveRateWithInsuranceIsLegal : false
  }, [possibleTerms, term])

  const resetPayments = useCallback(() => {
    merchantList.forEach((_merchant, index) => {
      setValue(`merchantPayments.${index}.amount`, 0)
    })
  }, [merchantList, setValue])

  useEffect(() => {
    if (
      term === Constants.LongTerm &&
      amountRequested < Constants.LargeLoanAmount &&
      creditApplication?.finalCreditDecision.maxTermDuration
    )
      setValue('term', creditApplication?.finalCreditDecision.maxTermDuration)
  }, [amountRequested, term, creditApplication?.finalCreditDecision.maxTermDuration, setValue])

  const onMerchantsSelected = useCallback(
    (newMerchants: Merchant[]) => {
      setOpenUserDialog(false)
      resetPayments()
      setMerchantList(newMerchants)
    },
    [resetPayments, setMerchantList],
  )

  const navigateToView = (financingProgramId: string, id: string) => {
    navigate(`/credits/${financingProgramId}/${id}`)
  }

  const onSubmit = async (formData: EditPersonalLoanWorksheetDto) => {
    merchantList.forEach((merch, index) => {
      formData.merchantPayments[index].merchantId = merch.id
      formData.merchantPayments[index].paymentMethod = merch.defaultPaymentMethod
    })
    if (creditApplication.worksheet == null) {
      await postWorksheet({ worksheet: createNewPersonalLoanWorksheetDto(creditApplication.id, formData) }).then(() => {
        navigateToView(creditApplication.financingProgramId, creditApplication.id)
      })
    } else {
      await putWorksheet({
        worksheet: updatePersonalLoanWorksheet(formData, creditApplication) as unknown as EditPersonalLoanWorksheetDto,
      }).then(() => {
        navigateToView(creditApplication.financingProgramId, creditApplication.id)
      })
    }
  }

  useEffect(() => {
    if (!user.isBannerUser && amountRequested) setValue(`merchantPayments.0.amount`, amountRequested)
  }, [amountRequested, setValue, user.isBannerUser])

  useEffect(() => {
    if (merchant?.paymentPlans) {
      const cTerm = merchant.paymentPlans.find((plan) => plan.id === paymentPlanId)
      if (cTerm) {
        setValue('term', cTerm.loanTerm, { shouldValidate: true })
      }
    }
  }, [merchant.paymentPlans, paymentPlanId, setValue])

  const isAPRTooHigh = useMemo(() => {
    const address = creditApplication.applicant.currentAddress
    if (!address || !computedPersonalLoanFunding) return false

    const { effectiveRate } = computedPersonalLoanFunding
    const { stateIso } = address

    const aprThresholds: Record<string, number> = {
      [EProvince.newfoundland]: 27,
    }

    const threshold = aprThresholds[stateIso!]

    return threshold !== undefined && effectiveRate >= threshold
  }, [computedPersonalLoanFunding, creditApplication.applicant.currentAddress])

  useEffect(() => {
    const firstDate = formatDate(firstPaymentDateOptions?.[0]?.toISOString())
    setValue('firstPaymentOn', firstDate, { shouldValidate: true })
  }, [firstPaymentDateOptions, setValue])

  const hasConflict = updateError?.response?.status === 409 || postError?.response?.status === 409
  const handleClose = useCallback(() => {
    if (updateError?.response?.status === 409) {
      resetPut()
    }
    if (postError?.response?.status === 409) {
      resetPost()
    }
  }, [resetPost, resetPut, updateError, postError])

  if (isFetchingFeatureSwitch) {
    return <PageSpinner withBackdrop isLoading={isFetchingFeatureSwitch} />
  }

  return (
    <div>
      {(postError || updateError) && !hasConflict && (
        <Paper>
          <Alert severity="error">
            <Typography>{postError?.response?.data?.message || updateError?.response?.data?.message}</Typography>
          </Alert>
        </Paper>
      )}
      {hasConflict && (
        <ConflictDialog
          updatedByUserFullname={conflict?.updatedByUserFullname}
          updatedOn={conflict?.updatedOn}
          open={hasConflict}
          onClose={handleClose}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={{ md: 'column', xs: 'column-reverse' }} spacing={5} justifyContent="space-between">
          <Stack
            direction={{ sm: 'row', xs: 'column' }}
            spacing={2}
            justifyContent="space-between"
            alignItems={{ sm: 'self-end', xs: 'self-start' }}
          >
            <Stack direction="column" justifyContent="space-between">
              <Typography variant="h4">{t('worksheet.worksheet')}</Typography>
              <Typography variant="h5">
                {t('worksheet.maxLoanAmount')}:{' '}
                {FormatCurrency(creditApplication.finalCreditDecision.maxAmountFinanced)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} display="flex">
              <AsyncActionButton fullWidth={false} variant="contained" onClick={() => navigate(-1)}>
                {t('common.previous')}
              </AsyncActionButton>

              <AsyncActionButton
                fullWidth={false}
                variant="contained"
                disabled={isAPRTooHigh || isComputing || !computedPersonalLoanFunding}
                isPending={isPosting || isPuting}
                isError={isPostError || isPutError}
                onClick={handleSubmit(onSubmit)}
              >
                {t('worksheet.createContract')}
              </AsyncActionButton>
            </Stack>
          </Stack>

          <Stack direction={{ md: 'row', xs: 'column-reverse' }} spacing={5} justifyContent="space-between">
            <Box sx={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <WorksheetDetails
                    control={control}
                    watch={watch}
                    register={register}
                    errors={errors}
                    creditApplication={creditApplication}
                    listHolidays={listHolidays}
                    merchantPayments={merchantPayments}
                    loanTermChoicesList={loanTermChoicesList}
                    isLoadingTerm={isLoadingTerms}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    {...register('includeInsurance')}
                    control={<Checkbox defaultChecked={creditApplication.worksheet?.includeInsurance} />}
                    label={t('worksheet.addInsurance')}
                    disabled={!doesProvinceSupportInsurance || !doesTermSupportInsurance}
                  />
                  <Tooltip title={t('worksheet.includeInsurance')}>
                    <IconButton
                      onClick={() => setOpenInsuranceInfoBox((prev) => !prev)}
                      style={{ marginLeft: '-15px' }}
                    >
                      <InfoIcon style={{ fontSize: 'medium' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>

                {openInsuranceInfoBox && (
                  <Box component="div" sx={{ padding: '10px', marginLeft: '15px' }}>
                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                      {doesProvinceSupportInsurance
                        ? t('worksheet.insuranceIncitative')
                        : t('worksheet.notAvailableInsurance')}
                    </Typography>
                    {doesProvinceSupportInsurance && (
                      <ul style={{ paddingLeft: '20px' }}>
                        <li>
                          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                            {t('worksheet.lifeInsurance')}
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                            {t('worksheet.accidentalDisability')}
                          </Typography>
                        </li>
                      </ul>
                    )}
                  </Box>
                )}
                <Grid item xs={12}>
                  <Stack sx={{ mb: 2 }} direction="row" alignItems="center">
                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                      {t('worksheet.paymentBreakdown')}
                    </Typography>
                    {user.isBannerUser && (
                      <>
                        <AsyncActionButton
                          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                          fullWidth={false}
                          color="secondary"
                          variant="contained"
                          onClick={() => setOpenUserDialog(true)}
                        >
                          {t('worksheet.addMerchantPayment')}
                        </AsyncActionButton>
                        <Tooltip title={t('worksheet.addMerchantPayment')}>
                          <IconButton
                            onClick={() => setOpenUserDialog(true)}
                            color="secondary"
                            sx={{ display: { md: 'none' } }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Stack>

                  <Stack spacing={2}>
                    {merchantList &&
                      merchantList.map((m, index) => {
                        return (
                          <div>
                            <Grid container key={m.id} spacing={1}>
                              <Grid item xs={12} sm={8}>
                                <Typography sx={{ mt: { sm: 2 } }}>{m.name}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <InputTextField
                                  disabled={!user.isBannerUser}
                                  {...register(`merchantPayments.${index}.amount`)}
                                  label={t('worksheet.paymentAmount')}
                                  InputProps={{
                                    endAdornment: '$',
                                  }}
                                  onBlur={() => trigger('amountRequested')}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )
                      })}
                  </Stack>
                </Grid>
                <Grid item container xs={12} sx={{ display: { md: 'none' } }}>
                  <Grid item xs={12} sm={6}>
                    <AsyncActionButton color="error" onClick={() => navigate(-1)}>
                      {t('common.previous')}
                    </AsyncActionButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AsyncActionButton
                      variant="contained"
                      color="primary"
                      disabled={isAPRTooHigh || isComputing || !computedPersonalLoanFunding}
                      isPending={isPosting || isPuting}
                      isError={isPostError || isPutError}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('worksheet.createContract')}
                    </AsyncActionButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                border: 1,
                padding: 2,
              }}
            >
              <PersonalLoanFundingSummary
                creditApplication={creditApplication}
                amountRequested={amountRequested}
                hasVariableInterest={hasVariableInterest}
                firstPaymentOn={firstPaymentOn}
                merchant={merchant}
                paymentPlanId={paymentPlanId}
                provinceSupportsInsurance={doesProvinceSupportInsurance}
                computedPersonalLoanFunding={computedPersonalLoanFunding}
                isComputing={isComputing}
              />
            </Box>
          </Stack>
        </Stack>
      </form>
      {user.isBannerUser && (
        <SelectMerchantsDialog
          open={openUserDialog}
          title={t('worksheet.addMerchantPayment')}
          label={t('common.merchant')}
          merchantsTotal={merchantsListFromParentId}
          merchantsSelected={merchantList}
          onConfirm={onMerchantsSelected}
          onCancel={onClosedMerchantDialog}
        />
      )}
    </div>
  )
}

export default PersonalLoanWorksheet
